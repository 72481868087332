<template>
  <div>
    <Header></Header>
    <div class="banner">
      <img :src="`${$url}/banner/3.png`" alt="">
      <div class="all-title-ani">物联网智慧医疗</div>
      <div class="all-title-ani all-ani-delay" style="width: 8rem;">为基于物联网的各行业设备制造商、方案商提供一站式应用开发服务。通过统一的、高度集成的物联网平台，使各种应用功能基于同一个平台进行呈现、使用、管理。让数据流动成为精细化管理的大脑，更有效率的流转，更有默契的协同。</div>
    </div>
    <div class="internet">
      <div class="internet-advantages">
        <div class="All-title">产品优势</div>
        <div class="All-box">
          <div>
            <div>
              <img :src="`${$url}/internet/1.png`" alt="">
              <img :src="`${$url}/internet/16.png`" alt="">
              <div>应用层</div>
              <div>基于历史交付和实践经验所设计的应用模板，可帮助客户有效缩减应用的开发时间。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/2.png`" alt="">
              <img :src="`${$url}/internet/17.png`" alt="">
              <div>平台层</div>
              <div>完善的可视化开发和管理功能，帮助客户轻松完成设备端的"管、控、营"一体化。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/3.png`" alt="">
              <img :src="`${$url}/internet/18.png`" alt="">
              <div>传输层</div>
              <div>借助智能网关及SDK，帮助客户快速接入各类设备，让客户的数据更迅速、安全的流动。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/4.png`" alt="">
              <img :src="`${$url}/internet/19.png`" alt="">
              <div>感知层</div>
              <div>广泛兼容标准协议，面对各种复杂的设备部署方案游刃有余, 帮助客户有效降低实施成本。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/5.png`" alt="">
              <img :src="`${$url}/internet/20.png`" alt="">
              <div>体验层</div>
              <div>使用云数智康平台可以轻松的开发跨平台的物联网应用，以高度灵活的适配数据需求。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/6.png`" alt="">
              <img :src="`${$url}/internet/21.png`" alt="">
              <div>开发层</div>
              <div>告别各类工具的沉重束缚，使用桌面端，移动端访问, 有效降低开发运维的成本和复杂度。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="internet-Technical">
        <div class="All-title">技术优势</div>
        <div class="All-box2">
          <div>
            <img :src="`${$url}/internet/8.png`" alt="">
            <div>更加轻量</div>
            <div>基于历史交付和实践经验所设计的应用模板，可帮助客户有效缩减应用的开发时间，提升交付品质。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/9.png`" alt="">
            <div>更加强大</div>
            <div>真正开箱即用，全力推动客户价值的极速落地可视化的开发方式轻松打造物联网应用。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/10.png`" alt="">
            <div>更加简单</div>
            <div>在线应用使能平台，可视化的开发方式，轻松打造物联网应用。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/11.png`" alt="">
            <div>更加柔性</div>
            <div>在线热更新，避免停服升级造成的延误和损失，极速响应业务变更。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/12.png`" alt="">
            <div>更加纯粹</div>
            <div>更纯粹的开放平台，支持纵向扩展，以打造用户的自有物联网平台。</div>
          </div>
          <div>
            <img :src="`${$url}/internet/13.png`" alt="">
            <div>更加智能</div>
            <div>使用云数智康通用网关接入各种现场设备，从容应对现场环境。</div>
          </div>
        </div>
      </div>
      <div class="program-value">
        <div class="All-title">方案价值</div>
        <div class="program-box">
          <div>
            <div>
              <div>01</div>
              <div>降低运营成本</div>
              <div>通过分散采集，统一监控、集中管理减少维护成本、降低劳动强度，改善工作环境变分散式维护管理为集中式维护管理。</div>
            </div>
          </div>
          <div>
            <div>
              <div>01</div>
              <div>增强产品竞争力</div>
              <div>由单纯卖设备转换为提供设备+服务，基于系统对产品运行信息分析，为今后产品改进提供强大的数据。</div>
            </div>
          </div>
          <div>
            <div>
              <div>03</div>
              <div>提高服务水平</div>
              <div>实时了解设备的故障信息并做相应的决策和处理，基于系统反馈的故障处理信息，不断的改进服务方式，提高服务量。</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
.internet {
  .internet-advantages {
    padding-top: 100px;
    background: #f7faff;
    padding-bottom: 90px;
  }
  .internet-Technical {
    width: 100%;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/internet/14.png')
      no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    text-align: center;
    padding-bottom: 90px;
    padding-top: 100px;
  }
  .program-value {
    padding-top: 100px;
    padding-bottom: 32px;
    background-color: #f5f6f8;
  }
}
</style>
